<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="formLoader">
                    <b-row>
                       <b-col xl="12" lg="12" sm="12">
                          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                              <ValidationProvider name="Budget Type" vid="budget_type_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="budget_type_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                          {{$t('research_manage.budget_type')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                          plain
                                          v-model="budgetHead.budget_type_id"
                                          :options="budgetTypeList"
                                          id="budget_type_id"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                          <template v-slot:first>
                                              <b-form-select-option :value="0" disabled >{{$t('globalTrans.select')}}</b-form-select-option>
                                          </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                          {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>

                              <ValidationProvider name="Budget Head En" vid="budget_head_en" rules="required|max:100">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="budget_head_en"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                      {{ $t('research_manage.budget_head_en') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="budget_head_en"
                                    v-model="budgetHead.budget_head_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                  </b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>

                              <ValidationProvider name="Budget Head En" vid="budget_head_bn" rules="required|max:100">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="budget_head_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                      {{ $t('research_manage.budget_head_bn') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="budget_head_bn"
                                    v-model="budgetHead.budget_head_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                  </b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                              <ValidationProvider name="Budget Head Code" vid="budget_head_code" rules="required|integer">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="budget_head_code"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                      {{ $t('research_manage.budget_head_code') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="budget_head_code"
                                    v-model="budgetHead.budget_head_code"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                  </b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            <div class="row">
                              <div class="col text-right">
                                  <b-button type="submit" variant="primary" class="mr-1">{{ saveBtnName }}</b-button>
                                  &nbsp;
                                  <b-button variant="danger" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                              </div>
                            </div>
                          </b-form>
                          </ValidationObserver>
                      </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { budgetHeadStoreApi, budgetHeadUpdateApi } from '../../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getBudgetHeadData()
      this.budgetHead = tmp
    }
  },
  mounted () {
  },
  data () {
    return {
      formLoader: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      budgetHead: {
        budget_type_id: 0,
        budget_head_code: '',
        budget_head_en: '',
        budget_head_bn: ''
      }
    }
  },
  computed: {
    budgetTypeList: function () {
        return this.$store.state.AgriResearch.commonObj.budgetTypeList.filter(item => item.status === 1).map(el => {
            return Object.assign({}, el, { value: el.value, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })
    }
  },
  methods: {
    getBudgetHeadData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.formLoader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })

      let result = null

      if (this.budgetHead.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${budgetHeadUpdateApi}/${this.id}`, this.budgetHead)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, budgetHeadStoreApi, this.budgetHead)
      }

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })

        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }

      this.formLoader = false
    }
  }
}
</script>
